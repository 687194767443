import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { palette } from "../utils/constants"
import Layout from "../components/layout"

require(`katex/dist/katex.min.css`)

const PostTitle = styled.div`
    font-size: 2rem;
    line-height: 2rem;
    color: ${palette.light.primary};
    margin-bottom: 0.5rem;
    font-family: "Zilla Slab";
    font-weight: 700;
`

const PostSubtitle = styled.div`
    font-size: 1rem;
    line-height: 1rem;
    color: ${palette.light.secondary};
    margin-bottom: 0.5rem;
    font-family: "Zilla Slab";
    font-weight: 400;
    font-style: italic;
`

const PostDate = styled.div`
    font-size: 0.75rem;
    line-height: 0.75rem;
    color: ${palette.light.tertiary};
    margin-bottom: 2rem;
    font-family: "Zilla Slab";
    font-weight: 700;
`

const PostFormat = styled.div`
    padding-top: 1rem;
    h1, h2, h3, h4, h5, h6 {
        color: rgba(48,49,57,0.9);
        font-family: 'Fira Sans', sans-serif;
        font-weight: 500;
    }

    blockquote {
        border-left: 8px solid ${palette.light.highlight};
        padding: 1rem;
        margin: 0rem 0rem 1.5rem 0rem;
        background: rgba(238, 235, 232, 0.25);
        color: ${palette.light.secondary};
        border-radius: 0.25rem;
    }

    code {
        font-family: 'Fira Code', monospace;
        font-size: 14px;
        overflow: auto;
    }

    a {
        color: ${palette.light.highlight};
    }

    p > code {
        background-color: ${palette.light.backgroundHighlight};
        border-radius: 0.25rem;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
        color: #00459F;
    }

    p > img {
        max-width: 100%;
        height: auto;
    }

    p > span {
        margin-left: 0 !important;
    }
`

const BlogPost = ({ data }) => {
    const post = data.markdownRemark
    return (
        <Layout>
            <title>{data.site.siteMetadata.title} | {post.frontmatter.title}</title>
            <PostFormat>
                <PostTitle>{post.frontmatter.title}</PostTitle>
                <PostSubtitle>{post.frontmatter.subtitle}</PostSubtitle>
                <PostDate>{post.frontmatter.date}</PostDate>
                <div dangerouslySetInnerHTML={{ __html: post.html }} />
            </PostFormat>
        </Layout>
    )
}

export default BlogPost

export const query = graphql`
    query($slug: String!) {
        site {
            siteMetadata {
                title
            }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                date(formatString: "DD MMMM, YYYY")
                subtitle
            }
        }
    }
`